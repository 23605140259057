import React from 'react';
import styled from 'styled-components';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';
import ProductGrid from '../../ProductList/ProductGrid';
import {Button} from 'antd';
import {AppleFilled, AndroidFilled} from '@ant-design/icons';
import * as AppActions from '../../../AppActions';
import {mapLineBreak} from '../../../Utils/TextUtil';
import CustomCarousel from './CustomCarousel';

const THEME_COLOR = '#708a93';
const THEME_COLOR_LIGHT = '#add8e6';

const THEME_COLOR_OPACITY = (opacity) => `rgba(112, 138, 147, ${opacity})`;
const THEME_COLOR_LIGHT_OPACITY = (opacity) =>
  `rgba(173, 216, 230, ${opacity})`;

function Landing(props) {
  const {landing} = props;
  const [products, setProducts] = React.useState([]);
  const [articles, setArticles] = React.useState([]);

  React.useEffect(() => {
    async function fetchData() {
      async function fetchProducts() {
        let productIds = landing.custom_layout.product_section || [];
        let products = [];

        let _products = await JStorage.fetchDocuments(
          'product',
          {
            _id: {
              $in: [
                ...productIds.map((id) => ({
                  $oid: id,
                })),
              ],
            },
          },
          null,
          null,
          null,
          {anonymous: true},
        );

        for (let id of productIds) {
          let p = _products.find((p) => p.id === id);
          if (p) {
            products.push(p);
          }
        }

        return products;
      }

      async function fetchArticles() {
        let articleIds = landing.custom_layout.article_section || [];
        let articles = [];

        let _articles = await JStorage.fetchDocuments(
          'Article_Default',
          {
            _id: {
              $in: [
                ...articleIds.map((id) => ({
                  $oid: id,
                })),
              ],
            },
          },
          null,
          null,
          null,
          {anonymous: true},
        );

        for (let id of articleIds) {
          let p = _articles.find((p) => p.id === id);
          if (p) {
            articles.push(p);
          }
        }

        return articles;
      }

      AppActions.setLoading(true);
      try {
        const [productResp, articleResp] = await Promise.all([
          fetchProducts(),
          fetchArticles(),
        ]);
        setProducts(productResp);
        setArticles(articleResp);
      } catch (ex) {
        console.warn(ex);
      }
      AppActions.setLoading(false);
    }

    fetchData();
  }, [landing, setProducts]);

  return (
    <>
      <div style={{height: 64}} />
      <CustomCarousel landing={landing} />
      <HeroSection>
        <div className="background1" />
        <div className="background2" />
        <div className="slogan">
          <h2>
            NFC 專家優勢網 ｜
            <span style={{color: THEME_COLOR}}>NFCmaker.io</span>
          </h2>

          <div
            className="description"
            dangerouslySetInnerHTML={{
              __html: mapLineBreak(landing.custom_layout.site_description),
            }}
          />
        </div>
      </HeroSection>

      <ExpertSection>
        <h2 id="export">我們的專家</h2>

        <div className="expert-list">
          <div className="expert">
            <figure>
              <img
                src="https://avatars.githubusercontent.com/u/17289414?v=4"
                alt="expert"
              />
            </figure>
            <div className="bio">
              <h2>Roger Luo</h2>
              <p className="slogan">{`無可救藥的熱情主義者，\n安靜忠誠`}</p>
              <p>
                年輕時代在「天龍國」求學，畢業後即在科技的風城成為留鳥，為萬象創造股份有限公司的創辦人。喜歡研究新奇事物，多年來投身RFID/NFC的應用小有心得，願意熱切與讀者分享研究的成果。
              </p>
            </div>
          </div>

          <div className="expert">
            <figure>
              <img
                src="https://avatars.githubusercontent.com/u/10307875?v=4"
                alt="expert"
              />
            </figure>
            <div className="bio">
              <h2>Richie Hsieh</h2>
              <p className="slogan">{`堅持耕耘的技術職人，\n熱情專注`}</p>
              <p>
                忻旅科技 (Revteltech) 共同創辦人兼技術長. 曾任職於聯發科
                (Mediatek) , 擔任軟體技術主管. 同時兼任知名技術學習網站
                fullstack.io 講師，在 React Native 開源社群中開發並維護主要 NFC
                函式庫 react-native-nfc-manager。
              </p>
            </div>
          </div>
        </div>
      </ExpertSection>

      <ProductSection>
        <h2>推薦文章</h2>
        <div className="product-list">
          <ProductGrid
            products={articles}
            prefixPath={'/articles'}
            onItemClick={(item) =>
              AppActions.navigate(`/article/?id=${item.id}`)
            }
          />
        </div>
        <Button
          size="large"
          style={{display: 'block', margin: '20px auto'}}
          onClick={() => AppActions.navigate('/articles')}>
          查看所有文章
        </Button>
      </ProductSection>

      <AppSection>
        <h2>NFC ReWriter：您最佳的 NFC 助手 APP</h2>
        <img src="/images/nfc-rewriter-icon.png" alt="nfc-open-rewriter" />

        <div className="info">
          <h3>
            NFC ReWriter is an open source project built on-top-of React-Native.
          </h3>
          <p>
            This app could help you easily write NDEF message to NFC Forum tag.
            Also let you write custom transceive commands in TOOLKIT. The
            features are:
          </p>
          <ul>
            <li>READ TAGS : scan NFC tag and show details</li>
            <li>WRITE NDEF: write "well-known" and "MIME" records</li>
            <li>TOOL KIT: support "Custom Transceive", NDEF Format,...etc.</li>
            <li>TAG KIT: support tag-specify function call</li>
            <li>MY RECORDS: keep and share command list files</li>
          </ul>
        </div>

        <div className="download-links">
          <a
            target="_blank"
            href="https://apps.apple.com/tw/app/nfc-rewriter/id1551243964"
            alt="app store"
            rel="noreferrer">
            <Button
              type="text"
              icon={<AppleFilled style={{color: THEME_COLOR}} />}>
              iOS Download
            </Button>
          </a>
          <a
            target="_blank"
            href="https://play.google.com/store/apps/details?id=com.washow.nfcopenrewriter"
            alt="google play store"
            rel="noreferrer">
            <Button
              type="text"
              icon={<AndroidFilled style={{color: THEME_COLOR}} />}>
              Android Download
            </Button>
          </a>
        </div>
      </AppSection>

      <ProductSection>
        <h2>推薦商品</h2>
        <div className="product-list">
          <ProductGrid
            products={products}
            prefixPath={'/products'}
            onItemClick={(item) =>
              AppActions.navigate(`/product/?id=${item.id}`)
            }
          />
        </div>
        <Button
          size="large"
          style={{display: 'block', margin: '20px auto'}}
          onClick={() => AppActions.navigate('/products')}>
          查看所有產品
        </Button>
      </ProductSection>

      <ProductSection bgColor={`${THEME_COLOR_LIGHT_OPACITY(0.5)}`}>
        <h2>服務內容</h2>

        <div className="service-list">
          {[
            [
              '顧問諮詢',
              '提供彈性合作方式協助開發 NFC 相關應用及硬體。團隊具豐富海內外合作經驗，曾協助 BANDAI 萬代玩具開發及國內知名鎖廠開發智能鎖。',
            ],
            [
              '成品採購',
              '販售完整 NFC 產品，如雲端標籤及加密貨幣冷錢包。自標籤至驗證用 APP 一應俱全，讓您的應用場景水到渠成。',
            ],
            [
              '專案合作',
              '提供自軟體、硬體到商品設計的一站式服務。曾開發工廠巡檢、老人照護及文創商品等多個領域應用，降低您產品開發風險。',
            ],
          ].map((item, idx) => (
            <div key={idx} className="service-item">
              <h3>{item[0]}</h3>
              <p>{item[1]}</p>
            </div>
          ))}
        </div>
      </ProductSection>
    </>
  );
}

const HeroSection = styled.div`
  min-height: 300px;
  padding: 65px 40px 40px 40px;
  position: relative;

  & .background1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #eee;
    z-index: -1;
  }

  & .background2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      to bottom right,
      ${THEME_COLOR_LIGHT_OPACITY(0.8)},
      ${THEME_COLOR_OPACITY(0.5)}
    );
    clip-path: polygon(0% 0%, 100% 0%, 100% 60%, 0% 100%);
    z-index: -1;
  }

  & .slogan {
    margin: 0 auto;
    padding: 30px;
    border-radius: 20px;
    max-width: 800px;
    background-color: white;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.1);
    transform: translate(0, 40px);
    display: flex;
    flex-direction: column;
    align-items: center;
    transition: all 200ms;

    & > .description {
      font-size: 20px;
      text-align: center;
      line-height: 1.6;
    }

    &:hover {
      box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.2);
    }

    & h2 {
      font-size: 1.7rem;
      font-weight: 600;
      margin-bottom: 15px;
    }

    & p {
      line-height: 2;
      text-align: center;
      font-size: 1rem;
    }

    & a {
      margin-top: 15px;
      padding: 3px;
      border-bottom: 1px solid ${THEME_COLOR_LIGHT};
      align-self: right;
      color: ${THEME_COLOR_LIGHT};
    }
  }
`;

const ExpertSection = styled.div`
  padding: 60px 0;
  background-color: #eee;

  & > h2 {
    display: block;
    font-size: 1.8rem;
    transform: translate(0, -30px);
    text-align: center;
    margin: 10px 0px;
  }

  & .expert-list {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    & .expert {
      margin: 20px;
      padding: 0px 15px 15px 15px;
      border-radius: 15px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 260px;
      min-height: 200px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
      background-color: white;
      position: relative;

      &:hover figure {
        width: 100px;
        height: 100px;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
      }

      & > figure {
        transition: all 200ms;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        display: block;
        transform: translate(0, -50%);
        border: 2px solid white;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);

        & > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          border-radius: 50%;
          overflow: hidden;
        }
      }

      & > .bio {
        margin-top: -20px;
        white-space: pre-wrap;

        & h2 {
          margin-bottom: 5px;
          text-align: center;
        }

        & p.slogan {
          font-weight: 400;
          color: ${THEME_COLOR};
          white-space: pre-wrap;
          text-align: center;
        }

        & p {
          margin-bottom: 10px;
        }
      }
    }
  }
`;

const AppSection = styled.section`
  background: ${THEME_COLOR_LIGHT_OPACITY(0.5)};
  padding: 35px 20px;

  & > h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    text-align: center;
  }

  & > img {
    width: 180px;
    height: 180px;
    object-fit: cover;
    display: block;
    margin: 20px auto;
  }

  & .info {
    max-width: 800px;
    margin: 20px auto;

    & h3 {
      margin-bottom: 10px;
      text-align: center;
    }

    & p {
      margin-bottom: 10px;
      text-align: center;
    }

    & ul {
      margin: 0 auto;
      max-width: 450px;
      width: 100%;

      & li {
      }
    }
  }

  & .download-links {
    display: flex;
    justify-content: center;
    & > a {
      margin: 20px;
      padding: 3px;
      color: gray;
      border-bottom: 1px solid white;
    }
  }
`;

const ProductSection = styled.section`
  padding: 60px 20px;
  min-height: 300px;
  background-color: ${(props) => props.bgColor || '#ffffff'};

  & > h2 {
    font-size: 1.8rem;
    margin-bottom: 15px;
    text-align: center;
  }

  & > .product-list {
    margin: 0 auto;
    max-width: 800px;
  }

  & a {
    padding: 3px;
    border-bottom: 1px solid ${THEME_COLOR_LIGHT};
    display: block;
    margin: 0 auto;
    color: ${THEME_COLOR_LIGHT};
  }

  & > .service-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;

    & > .service-item {
      border-radius: 10px;
      padding: 15px;
      margin: 20px;
      background-color: #ffffff;
      box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.05);
      width: 30%;
      min-width: 250px;
      min-height: 150px;

      & > h3 {
        font-size: 24px;
        color: ${THEME_COLOR};
      }

      & > p {
        margin-top: 5px;
        padding: 10px;
      }
    }
  }
`;

export default Landing;